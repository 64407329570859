import  Blogone from './Blogone';


function Servicesrender(){
    return(
        <>
        <Blogone />
        
        
        </>
    )
};
export default Servicesrender;