import  Blogtwo from './Blogtwo';


function Servicesrender(){
    return(
        <>
        <Blogtwo/>
        
        
        </>
    )
};
export default Servicesrender;